import React from "react";
import Select from "./select.js";
import Text from "./text.js";

const Name = (props) => {
  return (<fieldset className="group">
    <legend>{ props.label }</legend>
    <div>
      {
        props.inputs.map(input => {
          const Tag = input.inputType === "radio" ? Select : Text;
          return (<Tag
            {...props}
            {...input}
            type={ input.inputType }
          />);
        })
      }
    </div>
  </fieldset>);
};

export default Name;
