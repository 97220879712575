import React from "react";
import Field from "./field.js";

const Select = (props) => {
  const validationFunction = (value) => {
    return value.trim() !== "";
  };
  return (<Field
    {...props}
    tag="select"
    defaultValue={ props.defaultValue || props.choices[0].value }
    validationFunction={ validationFunction }
  >
    {
      props.choices.map((choice, i) => {
        return (<option key={i} value={ choice.value }>{ choice.text }</option>);
      })
    }
  </Field>);
};

export default Select;
