import React from "react";
import Field from "./field.js";

const Text = (props) => {
  const validationFunction = (value) => {
    return value.trim() !== "";
  };
  return (<Field {...props} validationFunction={ validationFunction } />);
};

export default Text;
